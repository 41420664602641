<script setup>
  import {ref} from 'vue'
  const message = ref('Helli')
</script>
<template>
  
  <p>{{ message }}</p>
  <!-- <router-view/> -->
</template>
<style>
html,
body {
  height: 100%;
  margin: 0;
}
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: azure;
}
.app-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* 使容器至少與視口高度相同 */
}
.main {
  flex: 1;
}
</style>
