import { createRouter, createWebHistory } from 'vue-router'

export const createMyRouter = function () {
  const routerlist = {
    Header: '../views/Header.vue',
    Footer: '../views/Footer.vue'
  }
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
      {
        path: '/',
        name: 'home',
        components: {
          Header: () => import(/* @vite-ignore */ routerlist.Header),
          Banner: () => import('../views/Frontpage.vue'),
          Footer: () => import(/* @vite-ignore */ routerlist.Footer)
        }
      },{
        path: '/api',
        name: 'home',
        components: {
          Header: () => import(/* @vite-ignore */ routerlist.Header),
          Banner: () => import('../views/Frontpage.vue'),
          Footer: () => import(/* @vite-ignore */ routerlist.Footer)
        }
      }
    ]
  })

  return router
}

export default createMyRouter